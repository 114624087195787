<template>
  <b-sidebar id="sidebar-fuel-price-edit" :visible="!!value" no-header @hidden="$emit('input', null)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t(`service.fuel.prices.${value}_an_airport`) }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer ref="airpostFormRef" class="p-5" tag="form" @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" lg="6">
            <span class="airport-title mb-1 mt-1">
              {{ $t('service.fuel.prices.airport') }}
            </span>
            <b-row>
              <!-- country -->
              <b-col cols="12">
                <enum-select
                  id="country"
                  :label="$t('common.country')"
                  :value="
                    editedAirport.country
                      ? {
                        value: editedAirport.country.code,
                        text: editedAirport.country.name,
                      }
                      : null
                  "
                  enum-class="CountryCode"
                  object-format
                  required
                  text="text"
                  @input="
                    val => {
                      if (editedAirport.country) {
                        editedAirport.country.code = val.value
                        editedAirport.country.name = val.text
                      } else {
                        editedAirport.country = { code: val.value, name: val.text }
                      }
                    }
                  "
                />
              </b-col>

              <!-- code ICAO -->
              <b-col cols="12">
                <app-input id="icao" v-model="editedAirport.icao" :label="$t('airport.icao')" rules="required" />
              </b-col>

              <!-- code IATA -->
              <b-col cols="12">
                <validation-provider>
                  <b-form-group :label="$t('airport.iata')" label-for="iata">
                    <v-select
                      v-model="editedAirport.iata"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="airportsOptions"
                      :placeholder="$t('airport.iata')"
                      :reduce="val => (typeof val === 'object' ? val.iataCode : val)"
                      input-id="iata"
                      label="iataCode"
                      taggable
                      @search="
                        search => {
                          searchLoader = true
                          searchAirports(search)
                        }
                      "
                      @open.once="fetchAirports()"
                    >
                      <template v-slot:no-options="{ search }">
                        <b-spinner v-if="searchLoader" small />
                        <template v-else-if="search">{{ $t('common.no_results_found_for', { search }) }}</template>
                        <template v-else>{{ $t('common.no_results_found') }}</template>
                      </template>

                      <!-- TODO: component + css for pagination -->
                      <template v-slot:list-footer="{ search }">
                        <li v-if="airports._attributes.items.length && !searchLoader && airports._attributes.lastPage > 1" class="pagination">
                          <button
                            :disabled="!airports._attributes.previousPage"
                            class="w-100"
                            @click="airports.fetch({ page: product.previous, name: search || null })"
                            v-text="$t('common.previous')"
                          />
                          <button
                            :disabled="!airports._attributes.nextPage"
                            class="w-100"
                            @click="airports.fetch({ page: product.nextPage, name: search || null })"
                            v-text="$t('common.next')"
                          />
                        </li>
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- supplier -->
              <b-col cols="12">
                <app-input id="supplier" v-model="editedAirport.supplier" :label="$t('service.fuel.prices.supplier')" />
              </b-col>

              <!-- uplift company -->
              <b-col cols="12">
                <app-input id="upliftCompany" v-model="editedAirport.upliftCompany" :label="$t('service.fuel.prices.uplift_company')" />
              </b-col>
            </b-row>
            <span class="base-title mb-1 mt-1"> {{ $t('service.fuel.prices.base_price') }}</span>
            <b-row>
              <!-- commercial flights -->
              <b-col cols="12">
                <app-input
                  id="commercialFlights"
                  v-model.number="editedAirport.commercialFlights"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.commercial_flights')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>

              <!-- mark up -->
              <b-col cols="12">
                <app-input
                  id="markUp"
                  v-model.number="editedAirport.commercialMarkUp"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.markup')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>
            </b-row>

            <span class="int-plane-uplift-title mb-1 mt-1">
              {{ $t('service.fuel.prices.into_plane_uplift') }}
            </span>
            <b-row>
              <!-- Uplift Cost Per Unit -->
              <b-col cols="12">
                <app-input
                  id="upliftCostPerUnit"
                  v-model.number="editedAirport.upliftPricePerQuantityUnit"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.uplift_cost_per_unit')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>

              <!-- hook up fee -->
              <b-col cols="12">
                <app-input
                  id="hookUpFee"
                  v-model.number="editedAirport.hookUpFee"
                  :append="getCurrency()"
                  :label="$t('service.fuel.prices.hook_up_fees')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="6">
            <span class="general-information-title mb-1 mt-1">
              {{ $t('service.fuel.prices.general_informations') }}
            </span>
            <b-row>
              <!--Start at-->
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('common.start_at')" class="validation-required" rules="required">
                  <b-form-group :label="$t('common.start_at')" label-for="start-at">
                    <b-form-datepicker
                      id="start-at"
                      v-model="editedAirport.startAt"
                      :locale="$i18n.locale"
                      :max="editedAirport.endAt"
                      :placeholder="$t('common.start_at')"
                      :state="errors[0] ? false : null"
                      class="mb-1"
                      size="md"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--End at-->
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('common.end_at')" class="validation-required" rules="required">
                  <b-form-group :label="$t('common.end_at')" label-for="end-at">
                    <b-form-datepicker
                      id="end-at"
                      v-model="editedAirport.endAt"
                      :locale="$i18n.locale"
                      :min="editedAirport.startAt"
                      :placeholder="$t('common.end_at')"
                      :state="errors[0] ? false : null"
                      class="mb-1"
                      size="md"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Public -->
              <b-col cols="12">
                <b-form-group :label="$t('service.fuel.prices.public_price')" label-for="public">
                  <b-form-select v-model="editedAirport.public" :options="publicOptions" />
                </b-form-group>
              </b-col>
            </b-row>
            <span class="logistic-fee-title my-1">
              {{ $t('service.fuel.prices.airport_and_logistic_fees') }}
            </span>
            <b-row>
              <!-- Airport concession -->
              <b-col cols="12">
                <app-input
                  id="motAndCo2"
                  v-model.number="editedAirport.airportConcessionFee"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.airport_concession')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>
              <!-- Storage -->
              <b-col cols="12">
                <app-input
                  id="storage"
                  v-model.number="editedAirport.storageFee"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.storage')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>
              <!-- Others Hydrant Fee -->
              <b-col cols="12">
                <app-input
                  id="othersHydrantFee"
                  v-model.number="editedAirport.otherHydrantFee"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.other_hydrant_fees')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>
            </b-row>
            <span class="vat my-1">
              {{ $tc('pricing.tax', 2) }}
            </span>
            <b-row>
              <!-- private flights -->
              <b-col cols="12">
                <app-input id="vat" v-model.number="editedAirport.vatRate" :label="$t('common.vat')" append="%" rules="positive" :formatter="numberFormat" />
              </b-col>
              <b-col cols="12">
                <app-input
                  id="motAndCo2"
                  v-model.number="editedAirport.motAndCarbonDioxide"
                  :append="$getMeasurementUnitItn('epl')"
                  :label="$t('service.fuel.prices.excise_and_ecotax')"
                  rules="positive"
                  :formatter="numberFormat"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div>
          {{ $t('common.note') }} {{ $t('service.fuel.prices.price_note') }}
        </div>

        <!-- submit and reset -->
        <div class="d-flex p-2 mt-2 justify-content-center">
          <b-row>
            <b-col cols="12">
              <b-button v-ripple.400 :disabled="fuelPriceLoading" class="mr-1" type="submit">
                {{ $t('action.submit') }}
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" @click="product.reset()">
                {{ $t('action.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent, inject, reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'
import AppQuantityPerUnitInput from '@/components/AppQuantityPerUnitInput.vue'
import AirportsCollection from '@/collections/AirportsCollection'
import getCurrency from '@/helpers/getCurrency'

export default defineComponent({
  name: 'ProductFuelPriceForm',

  props: {
    value: {
      type: String,
      default: null,
    },
    airportFuel: {
      type: Object,
      required: true,
    },
  },

  components: {
    AppQuantityPerUnitInput,
  },

  setup(props, ctx) {
    const { $i18n, toaster, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    const airpostFormRef = ref(null)
    const fuelPriceLoading = ref(false)

    const searchLoader = ref(false)
    const airports = reactive(new AirportsCollection())
    const airportsOptions = ref([])

    const fetchAirports = (page = null, name = null) => {
      searchLoader.value = true
      airports.fetch({ page, name }).then(() => {
        airportsOptions.value = airports._attributes.items
        searchLoader.value = false
      })
    }

    // TODO: search about iataCode after API
    const searchAirports = debounce(async search => {
      fetchAirports(null, search)
    }, 500)

    const publicOptions = [
      { value: true, text: $i18n.t('common.yes') },
      { value: false, text: $i18n.t('common.no') },
    ]
    const editedAirport = ref({
      country: null,
      icao: '',
      iata: '',
      supplier: '',
      upliftCompany: '',
      startAt: null,
      endAt: null,
      public: false,
      commercialFlights: 0,
      vatRate: 0,
      commercialMarkUp: 0,
      airportConcessionFee: 0,
      storageFee: 0,
      otherHydrantFee: 0,
      hookUpFee: 0,
      upliftPricePerQuantityUnit: 0,
      motAndCarbonDioxide: 0,
    })

    const initForm = _cloneDeep(editedAirport.value)

    const onSubmit = async () => {
      await airpostFormRef.value.validate().then(validForm => {
        if (validForm && editedAirport.value.country.code) {
          fuelPriceLoading.value = true
          const action = props.value === 'add' ? product.value.postPrice : product.value.patchPrice

          action(editedAirport.value)
            .then(() => {
              $emit('refresh-airports-table', props.value)
              $emit('input', null)
              editedAirport.value = initForm
            })
            .finally(() => {
              fuelPriceLoading.value = false
            })
        } else {
          toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })
    }

    watch(
      () => props.value,
      value => {
        airpostFormRef.value.reset()
        if (value === 'edit') {
          editedAirport.value = _cloneDeep(props.airportFuel)
        } else if (value === 'add') {
          editedAirport.value = {
            ...editedAirport.value,
            startAt: product.value.mainVariant.visibilityStartAt,
            endAt: product.value.mainVariant.visibilityEndAt,
          }
        } else if (!value) {
          editedAirport.value = initForm
        }
      },
    )

    const numberFormat = value => value.replace(/,/g, '.')

    return {
      product,
      airpostFormRef,
      editedAirport,
      publicOptions,
      onSubmit,
      fuelPriceLoading,
      fetchAirports,
      searchAirports,
      airportsOptions,
      searchLoader,
      airports,
      getCurrency,
      numberFormat,
    }
  },
})
</script>

<style lang="scss" scoped>
.airport-form-title {
  border: 1px solid #82868b;
  opacity: 0.75;
  font-weight: bold;
  border-radius: 5px;
  padding: 8px;
  display: block;
  justify-content: center;
  text-align: center;
}
.airport-title {
  @extend .airport-form-title;
  background-color: #5ea6ff !important;
  color: $theme-dark-body-bg !important;
}
.general-information-title {
  @extend .airport-form-title;
  background-color: #ffaa5b !important;
  color: $theme-dark-body-bg !important;
}
.base-title {
  @extend .airport-form-title;
  background-color: #ff5e5e !important;
  color: $theme-dark-body-bg !important;
}
.logistic-fee-title {
  @extend .airport-form-title;
  background-color: #ffff7f !important;
  color: $theme-dark-body-bg !important;
}
.int-plane-uplift-title {
  @extend .airport-form-title;
  background-color: #94ff7c !important;
  color: $theme-dark-body-bg !important;
}
.accises {
  @extend .airport-form-title;
  background-color: purple !important;
  color: white !important;
}
.vat {
  @extend .airport-form-title;
  background-color: blue !important;
  color: white !important;
}
</style>
